<template>
  <div class="home">

    <div class="page-title">
      <div class="content-container">
        <h1 class="title is-4">
          Welcome to CFE Projects & Maintenance
        </h1>
      </div>
    </div>

    <swiper ref="mySwiper" :options="swiperOptions" :auto-update="true">
      <swiper-slide><img src="~@/assets/img/slides/1.jpg"></swiper-slide>
      <swiper-slide><img src="~@/assets/img/slides/2.jpg"></swiper-slide>
      <swiper-slide><img src="~@/assets/img/slides/3.jpg"></swiper-slide>
      <swiper-slide><img src="~@/assets/img/slides/4.jpg"></swiper-slide>
      <swiper-slide><img src="~@/assets/img/slides/5.jpg"></swiper-slide>
      <swiper-slide><img src="~@/assets/img/slides/6.jpg"></swiper-slide>
    </swiper>

    <div class="content-container">

      <div class="icons">
        <router-link to="/design">
          <img src="~@/assets/img/home/design.png" alt="Design">
          <b>DESIGN</b>
        </router-link>
        <router-link to="manufacture">
          <img src="~@/assets/img/home/manufacture.png" alt="Design">
          <b>MANUFACTURE</b>
        </router-link>
        <router-link to="install">
          <img src="~@/assets/img/home/install.png" alt="Design">
          <b>INSTALL</b>
        </router-link>
        <router-link to="maintain">
          <img src="~@/assets/img/home/maintain.png" alt="Design">
          <b>MAINTAIN</b>
        </router-link>
      </div>

      <div class="concept">
        <h3 class="title is-4 center italic text-999">Concept to Completion and Beyond</h3>
      </div>

      <div class="center margin-top-20">
        <h3 class="title is-4 text-666 margin-bottom-0">CLIENTS</h3>
        <div class="client-images">
          <a target="_blank" href="https://www.asahi.com.au/"><img src="~@/assets/img/clients/Asahi.png" alt="Asahi"></a>
          <a target="_blank" href="https://www.camparigroup.com/en"><img src="~@/assets/img/clients/Campari.png" alt="Campari"></a>
          <a target="_blank" href="https://www.sunrice.com.au/"><img src="~@/assets/img/clients/Sunrice.png" alt="Sunrice"></a>
          <a target="_blank" href="https://www.mars.com/australia/en/brands/petcare.aspx"><img src="~@/assets/img/clients/Mars.png" alt="Mars"></a>
          <a target="_blank" href="https://www.schweppesaustralia.com.au/"><img src="~@/assets/img/clients/Schweppes.png" alt="Schweppes"></a>
          <a target="_blank" href="http://www.husky.ca/"><img src="~@/assets/img/clients/Husky.png" alt="Husk"></a>
          <a target="_blank" href="https://soy.com.au/"><img src="~@/assets/img/clients/Vitasoy.png" alt="Vitasoy"></a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        loop: true
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper ? this.$refs.mySwiper.$swiper : null
    }
  },
  created () {
  },
  mounted () {
    setInterval(() => {
      if (this.swiper) this.swiper.slideNext(400)
    }, 5000)
  }
}
</script>

<style lang="scss">
.home {
  .swiper-slide {
    height: 600px;
  }

  .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    a {
      width: 120px;
      margin: 20px;
      text-align: center;
      b {
        display: block;
        color: #666;
      }
    }
    a:hover {
      b {
        color: #000;
      }
    }
  }

  .concept {
    margin: 40px 0;
    padding: 40px 0;
    border-top: solid 1px #eee;
    border-bottom: solid 1px #eee;
  }

  .client-images {
    img {
      margin: 20px;
      width: 120px;
      height: 100px;
      object-fit: contain;
    }
  }
}
</style>
